

import {computed, defineComponent, onMounted, ref} from "vue";
import store from "@/store";
import MasterConfig from "@/core/config/MasterConfig";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {dateConvert} from "@/core/filters/datatime";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Field} from "vee-validate";
import {commonf} from "@/core/filters/common";
import reservationProductType from "@/core/data/reservationProductsTypetypes";
import reservationConfirmTypes from "@/core/data/reservationConfirmType";
import transfertypes from "@/core/data/transfertypes";
import {utils, writeFileXLSX} from "xlsx";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import TableOrganizer from "@/components/appcomps/tables/TableOrganizer.vue";


interface srcItem {
  srcFromDate: string;
  srcToDate: string;
  srcStartDate1: string;
  srcStartDate2: string;
  srcFinishDate1: string;
  srcFinishDate2: string;
  srcAccount: number;
  srcDepartment: number,
  srcMarket: number;
  srcPNR: string;
  srcVoucherNo: string;
  srcAccountVoucherNo: string;
  srcProductType: string;
}

const __default__ = defineComponent({
  name: "product-list",
  components: {
    Field,
    TableOrganizer,
  },
  setup() {

    onMounted(()=>{
      setDatePicker();
      store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
      store.dispatch(Actions.MARKETS_LIST_CACHE, {});
      click_Search();
      setCurrentPageBreadcrumbs("Ürün Rezervasyonlar", ["Rezervasyonlar", "Liste"]);
    });

    const srcItem = ref<srcItem>({
      srcFromDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcToDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcStartDate1: "",
      srcStartDate2: "",
      srcFinishDate1: "",
      srcFinishDate2: "",
      srcAccount: 0,
      srcDepartment: 0,
      srcMarket: 1,
      srcPNR: "",
      srcVoucherNo: "",
      srcAccountVoucherNo: "",
      srcProductType: "HOTEL",
    });

    var fpickStartDate1;
    var fpickStartDate2;
    var fpickFinishDate1;
    var fpickFinishDate2;

    var ccvLimit = ref(50);
    var ccvOffset = ref(0);
    var ccvPage = ref(1);
    var ccvTableStats = ref({});
    var ccvRequestCode = ref("ProductList");
    var arrTableList = ref([]);
    var valStyle_TableOrg_Th_Border = ref("2px solid#ececec");
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    var lstHeader = ref( [
      { columnName: "RowIndex", columnLabel: "Sıra", sortEnabled: true, },
      { columnName: "ProcessDate", columnLabel: "İşlem Tarihi", sortEnabled: true, },
      { columnName: "VoucherNo", columnLabel: "Voucher No", sortEnabled: true, },
      { columnName: "AccountVoucherNo", columnLabel: "A.Voucher No", sortEnabled: true, },
      { columnName: "PaxName", columnLabel: "Misafir", sortEnabled: true, },
      { columnName: "Product", columnLabel: "Satış/Ürün", sortEnabled: true, },
      { columnName: "ReservationDate", columnLabel: "Rez.Tarihi", sortEnabled: true, },
      { columnName: "Account", columnLabel: "Acente", sortEnabled: true, },
      { columnName: "TotalPrice", columnLabel: "Fiyat", sortEnabled: true, },
      { columnName: "CostPrice", columnLabel: "Maliyet", sortEnabled: true, },
      { columnName: "Status", columnLabel: "Durum", sortEnabled: true, },
      { columnName: "Action", columnLabel: "Aksiyon", sortEnabled: true, },
    ]);


    const pageChange = (prmPage: number, prmOffset: number, prmLimit: number) => {
      console.log("pageChange TableTest prmPage: "+prmPage+" prmOffset: "+prmOffset+" prmLimit: "+prmLimit);
      ccvOffset.value = prmOffset;
      sendReservationList(ccvRequestCode.value,  prmPage, prmOffset, prmLimit);
    };

    const processTorgRowsUpdate = ()=>{

      const collTorgTr = document.getElementsByClassName("torg_tr_"+ccvRequestCode.value);
      console.log("processTorgVisualsUpdate collTorgTr.length: "+collTorgTr.length);
      for (let i=0; i<collTorgTr.length; i++) {
        let eachTr = collTorgTr[i] as HTMLElement;
        let vvRowIndex = Number(eachTr.getAttribute("data-rowindex"));

        //RowIndex
        const collRowIndex = eachTr.getElementsByClassName("special-rowindex");
        const collRowIndexContrainer = eachTr.getElementsByClassName("special-rowindexcontainer");
        if (collRowIndex.length > 0) {
          //collRowIndex[0].innerHTML = "" + Number(ccvOffset.value + vvRowIndex + 1);
          collRowIndex[0].innerHTML = "" + Number( Number((ccvTableStats.value as any).RowCount)- Number(ccvOffset.value + vvRowIndex + 0));
        }
      }
    }

    function setTableStats(prmTableStats) {
      ccvTableStats.value = prmTableStats;
      console.log("setTableStats started and payload stats = " + JSON.stringify(prmTableStats));
      //{"MinAmount":"5.00","TotalAmount":"1880.00","AverageAmount":"17.90","MaxAmount":"50.00","Length":105}
    }


    function setDatePicker() {
      let tmpSrcFromDate = flatpickr("#srcFromDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcToDate = flatpickr("#srcToDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});

      fpickStartDate1 = flatpickr("#scr_ReservationsList_StartDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickStartDate2 = flatpickr("#scr_ReservationsList_StartDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });

      fpickFinishDate1 = flatpickr("#scr_ReservationsList_FinishDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickFinishDate2 = flatpickr("#scr_ReservationsList_FinishDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
    }

    const myProductList = computed(() => {
      return store.getters.reservationProductsList2;
    });

    const myAccountList = computed(() => {
      return store.getters.accountList;
    });

    const myDepartments = computed(() => {
      return store.getters.DepartmentsList;
    });

    const myMarkets = computed(() => {
      return store.getters.MarketsListCache;
    });

    function showHideSearchOtelInput(prmProductType) {
      const vvClass = document.getElementsByClassName("ReservationType_"+prmProductType)

      for(let i=0; i<vvClass.length; i++){
        let each = vvClass[i];
        const eachValue = each.getAttribute("value");
        if(eachValue!=='HOTEL'){
          document.getElementById("divHotelInput").style.display="none";
        }else {
          document.getElementById("divHotelInput").style.display="";
        }
      }

    }


    const myAccountsTitle = computed(() => {
      let tmpArray = [];
      if (myAccountList.value.data !== undefined) {
        for (var i of myAccountList.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });



    const getAccounts = () => {
      let myAccounts = document.getElementById("srcAccounts") as HTMLInputElement;
      let myPayload = {};
      if (myAccounts.value.length == 3) {
        myPayload = {
          'Title': myAccounts.value
        }
        store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
      }
    }
    const filterAccounts = (val) => {
      if (myAccountList.value.data != null) {
        return (myAccountList.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return true;
      }
    };


    const dataHotelList2 = computed(() => {
      let dataComputed = store.getters.hotelListTo;
      if (dataComputed!==undefined) {
        return dataComputed;
      }
      return {data:[]};
    });

    const myHotelsTitle = computed(() => {
      let tmpArray = [];
      if (dataHotelList2.value.data !== undefined) {
        for (var i of dataHotelList2.value.data) {
          tmpArray.push(i.Title);
        }
      }
      return tmpArray;
    });

    const getHotels = () => {
      let myHotels = document.getElementById("srcHotels") as HTMLInputElement;
      let myPayload = {};
      if (myHotels.value.length == 3) {
        myPayload = {
          'Title': myHotels.value,
          'CallFrom': "TO",
        }
        store.dispatch(Actions.HOTEL_LIST_ALL_TO, myPayload);
      }
    }

    const filterHotels = (val) => {
      if (dataHotelList2.value.data !== null) {
        return (dataHotelList2.value.data).filter(Items =>
            (Items.Title == val)
        );
      } else {
        return true;
      }
    };



    const click_Search = () => {
      sendReservationList(ccvRequestCode.value, 1, 0, ccvLimit.value);
    }

    function sendReservationList(prmRequestCode, prmPage: number, prmOffset: number, prmLimit: number ) {
      if (!submitButtonRef.value) {
        return;
      }
      arrTableList.value=[];
      ccvOffset.value = prmOffset;
      ccvPage.value = prmPage;

      let myAccount = document.getElementById("srcAccounts") as HTMLInputElement;
      let myHotel = document.getElementById("srcHotels") as HTMLInputElement;
      const payloadProductList = {
        ReservationID: "",
        ProductType: srcItem.value.srcProductType,
        // is_active: "active",
        //ProcessDate: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
        ProcessDateStart: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
        ProcessDateFinish: srcItem.value.srcToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcToDate) : "",
        StartDate1: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate1, 0),
        StartDate2: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate2, 0),
        FinishDate1: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate1, 0),
        FinishDate2: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate2, 0),
        VoucherNo: srcItem.value.srcVoucherNo,
        AccountVoucherNo: (srcItem.value.srcAccountVoucherNo).replace(/\\/g, ''),
        // AccountVoucherNo:  (typeof srcItem.value.srcAccountVoucherNo !="string") ? (JSON.stringify(srcItem.value.srcAccountVoucherNo)).replace(/\\/g, '') : '""',
        SupplierID: myHotel.value ? filterHotels(myHotel.value)[0].ID : "",
        Department: srcItem.value.srcDepartment,
        Market: srcItem.value.srcMarket,
        PNR: srcItem.value.srcPNR,
        //ProductType: "FLIGHT",
        is_active: "active",
        offset:prmOffset,
        limit:prmLimit,
        page:prmPage,
        requestcode:prmRequestCode,
        AccountID: myAccount.value ? filterAccounts(myAccount.value)[0].ID : "",

      }

      store.dispatch(Actions.RESERVATION_PRODUCTS_LIST_2, payloadProductList).then((payload) => {
        if(payload.requestcode===ccvRequestCode.value){
          arrTableList.value = payload.data;
          setTableStats(payload.stats);
        }
        if(payload.requestcode===ccvRequestCode.value + "ExpToExcel"){
          exportToExcel(payload.data);
        }
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
      }).catch((payload) => {
        libTools.method.swalError("Tamam", "Hata Oluştu");
      });
      submitButtonRef.value.disabled = false;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
    }

    function click_ExportToExcel() {
      console.log("click_ExportToExcel START");
      //sendReservationList(ccvRequestCode.value + "ExpToExcel", 1, 0, (ccvTableStats.value as any).RowCount);
      sendReservationList(ccvRequestCode.value + "ExpToExcel",  1, 0, 0);
    }

    function  getHotelTitle(prmHotelID){
          console.log("getHotelTitle " + prmHotelID);
          for(let j=0; j<dataHotelList.value.length; j++){
            let eachdata = dataHotelList.value[j];
            if(eachdata.ID===prmHotelID){
              return eachdata.Title;
            }
          }
     }


    const dataHotelList = computed(() => {
      let dataComputed = store.getters.hotelList;
      return dataComputed.data;
    });
    store.dispatch(Actions.HOTEL_LIST_ALL, {});


    function exportToExcel(prmReservationList) {
      let rv=[];
      for (let i=0; i<prmReservationList.length; i++) {
        let eachReservationList = prmReservationList[i];
        if(eachReservationList.ProductType==='TRANSFER'){
          console.log("product exportToExcel getHotelID " + libTools.method.getParsedObj(eachReservationList.RemarksJSON3).HotelID);
          let vvNew = {
            "İşlem Tarihi": eachReservationList.created_at,
            "Voucher No": eachReservationList.VoucherNo,
            "Acente Voucher No": eachReservationList.AccountVoucherNo,
            "Misafir": libTools.method.getAllPaxNameSurname(libTools.method.getRemarksJSONPaxes(eachReservationList.RemarksJSON1)),
            "Başlık": eachReservationList.Title,
            "Transfer Tarihi": dateConvert.method.convertToDateFormated(eachReservationList.StartDate, 'DD-MM-YYYY'),
            "Otel": getHotelTitle(libTools.method.getParsedObj(eachReservationList.RemarksJSON3).HotelID),
            "Transfer Yönü": libTools.method.getCoreDataByValue(transfertypes.TYPES, eachReservationList.RemarksVC3).Title,
            "Uçuş Numarası": libTools.method.getParsedObj(eachReservationList.RemarksJSON3).FlightNo,
            "Acente": eachReservationList.AccountTitle,
            "Maliyet": parseFloat(eachReservationList.TotalCurCost),
            "Satış": parseFloat(eachReservationList.TotalPrice),
            "Kur": eachReservationList.Cur,
            "Kullanıcı Adı": eachReservationList.Username,
            "Durum": libTools.method.getCoreDataByValue(reservationConfirmTypes.TYPES, eachReservationList.RemarksEnum1).Title,
          };
          rv.push(vvNew);
        }else {
          let vvNew = {
            "İşlem Tarihi": eachReservationList.created_at,
            "Voucher No": eachReservationList.VoucherNo,
            "Acente Voucher No": eachReservationList.AccountVoucherNo,
            "Misafir": libTools.method.getAllPaxNameSurname(libTools.method.getRemarksJSONPaxes(eachReservationList.RemarksJSON1)),
            "Başlık": eachReservationList.Title,
            "Alt Başlık": eachReservationList.RemarksVC3,
            "Giriş Tarihi": dateConvert.method.convertToDateFormated(eachReservationList.StartDate, 'DD-MM-YYYY'),
            "Çıkış Tarihi": dateConvert.method.convertToDateFormated(eachReservationList.FinishDate, 'DD-MM-YYYY'),
            "Acente": eachReservationList.AccountTitle,
            "Maliyet": parseFloat(eachReservationList.TotalCurCost),
            "Satış": parseFloat(eachReservationList.SecretProfit) + parseFloat(eachReservationList.TotalPrice),
            "Kur": eachReservationList.Cur,
            "Kullanıcı Adı": eachReservationList.Username,
            "Durum": libTools.method.getCoreDataByValue(reservationConfirmTypes.TYPES, eachReservationList.RemarksEnum1).Title,

          };
          rv.push(vvNew);
        }

      }

      const ws = utils.json_to_sheet(rv);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, ws, "Data");
      writeFileXLSX(wb, "Ürün Listesi" + ".xlsx");
      return rv;
    }






    return {
      dateConvert,
      libTools,
      commonf,
      MasterConfig,
      reservationProductType,
      reservationConfirmTypes,
      transfertypes,
      srcItem,
      myProductList,
      myAccountList,
      myDepartments,
      myMarkets,
      myHotelsTitle,
      myAccountsTitle,
      click_Search,
      getAccounts, getHotels,
      click_ExportToExcel,
      submitButtonRef,
      ccvLimit, ccvOffset, ccvPage, ccvTableStats, ccvRequestCode, lstHeader, arrTableList, pageChange, processTorgRowsUpdate, sendReservationList, valStyle_TableOrg_Th_Border,
      getHotelTitle,
      showHideSearchOtelInput,

    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "8e99b5a0": (_ctx.valStyle_TableOrg_Th_Border)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__